import Head from 'next/head'
import Link from 'next/link'
import React from 'react'
import { Button, Center, chakra, Flex, Text, VStack } from 'ui-lib'

import GradientSideLine from '@/src/components/atoms/GradientSideLine'
import logoInsetSvg from '@/src/svgs/logoInset.svg'

const Page404 = () => {
  return (
    <>
      <Head>
        <title>404 - Page not found</title>
      </Head>
      <Flex direction="column" minH="100vh">
        <GradientSideLine />
        <chakra.img width="32px" position="fixed" top="23px" left="33px" src={logoInsetSvg.src} />

        <chakra.div overflow="hidden" flex={1}>
          <Flex>
            <Flex direction={'column'} width={'100%'}>
              <Center h="100vh" w="100vw">
                <VStack>
                  <Text textStyle="t1.semibold" color="gray.500">
                    404
                  </Text>
                  <Text textStyle="h2.semibold" color="gray.500">
                    Page not found
                  </Text>
                  <Text textStyle={'regularText'} pt="4" pb="4">
                    Looks like you stumbled upon a missing page!
                  </Text>
                  <Button size="lg">
                    <Link href="/">Go home</Link>
                  </Button>
                </VStack>
              </Center>
            </Flex>
          </Flex>
        </chakra.div>
      </Flex>
    </>
  )
}

export default Page404
