import React from 'react'
import { chakra } from 'ui-lib'

import styles from './GradientSideLine.module.css'

interface GradientSideLineProps {
  position?: 'left' | 'right'
}

const GradientSideLine = ({ position = 'left' }: GradientSideLineProps) => {
  const props = {
    ...(position === 'left' && { left: 0 }),
    ...(position === 'right' && { right: 0 }),
  }
  return (
    <chakra.div className={styles.box} position="fixed" top="0" width="1rem" height="100vh" {...props}></chakra.div>
  )
}

export default GradientSideLine
